import { FC } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import RadialSeparators from "../../Components/CardControls/RadialSeparators";

interface RadialGraphProps {
    className?: string;
    imageUrl: string;
    value: number;
    hideRadial?: boolean;
}

const RadialGraph: FC<RadialGraphProps> = (props) => {
    return (
        <CircularProgressbarWithChildren
            className={"w-40 h-40 border-[1px] border-transparent" + (props.className ? ` ${props.className}` : "")}
            value={props.value}
            strokeWidth={props.hideRadial ? 0 : 8}
            styles={buildStyles({
                strokeLinecap: "butt",
                trailColor: "#e3e7f3",
                pathColor: "#fad23b",
            })}
        >
            <RadialSeparators
                count={60}
                className={"bg-white dark:bg-dark2-500"}
                style={{
                    width: "5px",
                    // This needs to be equal to props.strokeWidth
                    height: `${10}%`,
                }}
            />
            <div className="absolute inset-6 rounded-full shadow-xl">
                <div
                    className={"bg-no-repeat h-full w-full bg-contain bg-center scale-75 "}
                    style={{ backgroundImage: `url('${props.imageUrl}')` }}
                ></div>
            </div>
        </CircularProgressbarWithChildren>
    );
};

export default RadialGraph;
