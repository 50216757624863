import React, { useContext } from "react";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import LendingCard from "../Cards/StakeCard";
import SocialBar from "../Components/Social/SocialBar";
import RiskGraph, { RiskType } from "../Components/CardControls/RiskGraph";
import logoBomb from "../Images/logo512.png";
import logoBtcb from "../Images/btcb.png";

function LendPage() {
    const { lendingGraph, userPositions, stakeAsset, unstakeAsset, approveAsset, fetchAssetApprovalAmount, fetchAssetBalance } = useContext(Web3Context);
    const bomb = lendingGraph?.data.lendingPools[0].borrowable0;
    const btcb = lendingGraph?.data.lendingPools[0].borrowable1;

    const bombPositions = userPositions?.data.user?.supplyPositions.filter(x => x.borrowable.underlying.symbol == bomb?.underlying.symbol) || [];
    const btcbPositions = userPositions?.data.user?.supplyPositions.filter(x => x.borrowable.underlying.symbol == btcb?.underlying.symbol) || [];

    const busm = lendingGraph?.data.lendingPools[1].borrowable0;
    const busd = lendingGraph?.data.lendingPools[1].borrowable1;

    const busmPositions = userPositions?.data.user?.supplyPositions.filter(x => x.borrowable.underlying.symbol == bomb?.underlying.symbol) || [];
    const busdPositions = userPositions?.data.user?.supplyPositions.filter(x => x.borrowable.underlying.symbol == btcb?.underlying.symbol) || [];

    return (
        <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
            <h1 className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">
                Experience earning high returns without the stress{" "}
                <span className="italic font-extrabold whitespace-nowrap">
                    <span className="text-primary-500">//</span> BOMB Single Staking{" "}
                    <span className="text-primary-500">//</span>
                </span>
                <span className="pl-3 font-extrabold text-xl inline-block">
                    Low Risk <RiskGraph riskType={RiskType.LOW} />
                </span>
            </h1>
            <div className="mb-6 font-bold ">The easiest way to earn, simply stake your assets and earn.</div>

            <div className="max-w-lg lg:max-w-none rounded-lg flex-1 mt-2 mx-auto md:mx-0 contents md:block">
                <div className="gap-8 w-full flex flex-wrap justify-center md:justify-start">
                    {bomb != null ? <LendingCard
                        borrowable={bomb}
                        positions={bombPositions}
                        depositFunction={stakeAsset}
                        withdrawFunction={unstakeAsset}
                        imageUrl={logoBomb}
                        approveFunction={approveAsset}
                        getAllowanceFunction={fetchAssetApprovalAmount}
                        fetchAssetBalanceFunction={fetchAssetBalance}
                    /> : null}
                    {btcb != null ? <LendingCard
                        borrowable={btcb}
                        positions={btcbPositions}
                        depositFunction={stakeAsset}
                        withdrawFunction={unstakeAsset}
                        imageUrl={logoBtcb}
                        approveFunction={approveAsset}
                        getAllowanceFunction={fetchAssetApprovalAmount}
                        fetchAssetBalanceFunction={fetchAssetBalance}
                        /> : null}
                    {busm != null ? <LendingCard
                        borrowable={busm}
                        positions={busmPositions}
                        depositFunction={stakeAsset}
                        withdrawFunction={unstakeAsset}
                        imageUrl={logoBtcb}
                        approveFunction={approveAsset}
                        getAllowanceFunction={fetchAssetApprovalAmount}
                        fetchAssetBalanceFunction={fetchAssetBalance}
                    /> : null}
                    {busd != null ? <LendingCard
                        borrowable={busd}
                        positions={busdPositions}
                        depositFunction={stakeAsset}
                        withdrawFunction={unstakeAsset}
                        imageUrl={logoBtcb}
                        approveFunction={approveAsset}
                        getAllowanceFunction={fetchAssetApprovalAmount}
                        fetchAssetBalanceFunction={fetchAssetBalance}
                    /> : null}
                </div>
            </div>
            <SocialBar className="mt-12" />
        </main>
    );
}

export default LendPage;
