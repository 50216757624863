import React, { FC, useContext, useEffect, useState } from "react";
import { roundAndFormatNumber } from "../Common/HelperFunctions";
import { BombFactory, LendingPool } from "../Common/Models/LendingGraph";
import { BeefyFarmApyStats } from "../Common/Models/BeefyFarmApyStats";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import MiniPrice from "../Components/CardControls/MiniPrice";
import iconLock from "../Images/lock.png";
import iconPieChart from "../Images/pie-chart.png";
import iconStakedUsd from "../Images/staked-dollars-icon.png";
import iconStaked from "../Images/staked-icon.png";
import iconZap from "../Images/zap.png";
import RadialGraph from "../Components/CardControls/RadialGraph";
import ConnectButton from "../Components/ConnectButton/ConnectButton";
import StakeModal from "../Modals/StakeModal";
import { LpStat } from "../Common/Models/LPStat";
import ZapModal from "../Modals/ZapModal";

interface CollateralCardProps {
    className?: string;
    featured?: boolean;
    lendingPool?: LendingPool;
    farmApyStats?: BeefyFarmApyStats;
    lpStat?: LpStat;
    // farmTvlStats?: BeefyFarmTvlStats;
    imageUrl: string;
    bombFactories?: BombFactory[];

    depositFunction?: (poolAsset: string, amount: number) => void;
    withdrawFunction?: (poolAsset: string, amount: number) => void;
    approveFunction?: (poolAsset: string) => void;
    getAllowanceFunction?: (poolAsset: string) => Promise<number>;
    fetchAssetBalanceFunction?: (poolAsset: string) => Promise<number>;
    fetchAssetDebtFunction?: (poolAsset: string) => Promise<number>;
    fetchAssetExchangeRateFunction?: (poolAsset: string) => Promise<number>;
}

const FarmCard: FC<CollateralCardProps> = (props) => {
    const [stakedBalance, setStakedBalance] = useState<number>(0);
    const [stakedDebt, setStakedDebt] = useState<number>(0);
    const [stakedBalanceMultiplier, setStakedBalanceMultiplier] = useState<number>(1);
    const [walletBalance, setWalletBalance] = useState<number>(0);
    const [allowance, setAllowance] = useState<number>(0);
    const [withdrawAllowance, setWithdrawAllowance] = useState<number>(0);
    const { connectWeb3, disconnectWeb3, web3Connected, walletAddress } = useContext(Web3Context);
    const [stakeModalIsOpen, setStakeModalIsOpen] = React.useState(false);
    const [unstakeModalIsOpen, setUnstakeModalIsOpen] = React.useState(false);
    const [zapModalIsOpen, setZapModalIsOpen] = React.useState(false);
    const [debt0, setDebt0] = useState<number>(0); // This is to leverage
    const [debt1, setDebt1] = useState<number>(0);

    useEffect(() => {
        if (web3Connected) {
            fetchApproval();
            fetchWithdrawApproval();
            fetchAssetWalletBalance();
            fetchAssetStakedBalance();
            fetchAssetStakedDebt();
        }
    }, [props.lendingPool, web3Connected]);

    async function fetchApproval() {
        // debugger;
        if (props.getAllowanceFunction && props.lendingPool?.pair.uniswapV2PairAddress) {
            var allow = await props.getAllowanceFunction(props.lendingPool?.pair.uniswapV2PairAddress);
            setAllowance(allow);
        }
    }

    async function fetchWithdrawApproval() {
        // debugger;
        if (props.getAllowanceFunction && props.lendingPool?.collateral.id) {
            var withdrawAllow = await props.getAllowanceFunction(props.lendingPool?.collateral.id);
            setWithdrawAllowance(withdrawAllow);
        }
    }

    async function fetchAssetWalletBalance() {
        if (props.lendingPool && props.lendingPool.pair && props.fetchAssetBalanceFunction) {
            var balance = await props.fetchAssetBalanceFunction(props.lendingPool?.pair?.uniswapV2PairAddress);
            var trueBalance = balance;
            // debugger;
            setWalletBalance(trueBalance);
        }
    }

    async function fetchAssetStakedBalance() {
        if (props.fetchAssetBalanceFunction && props.fetchAssetExchangeRateFunction && props.lendingPool?.pair) {
            var balance = await props.fetchAssetBalanceFunction(props.lendingPool.collateral.id);
            var multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.collateral.id));
            var trueBalance = balance * multiplier;
            // debugger;
            setStakedBalance(trueBalance);
            setStakedBalanceMultiplier(multiplier);
        }
    }

    async function fetchAssetStakedDebt() {
        if (props.fetchAssetDebtFunction && props.fetchAssetExchangeRateFunction && props.lendingPool?.pair) {
            const debt0 = await props.fetchAssetDebtFunction(props.lendingPool.borrowable0.id);
            const debt1 = await props.fetchAssetDebtFunction(props.lendingPool.borrowable1.id);
            const multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.collateral.id));
            const debt0Usd = debt0 * props.lendingPool.borrowable0.underlying.derivedUSD;
            const debt1Usd = debt1 * props.lendingPool.borrowable1.underlying.derivedUSD;
            const lpUsd = props.lendingPool.pair.derivedUSD / multiplier;
            // var multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.borrowable0.id));
            // var trueDebt = debt * multiplier;
            // debugger;
            const debtInLps = (debt0Usd + debt1Usd) / lpUsd;
            setStakedDebt(debtInLps);
            setDebt0(debt0);
            setDebt1(debt1);
        }
    }

    async function withdraw(value: number) {
        if (props.withdrawFunction && props.fetchAssetExchangeRateFunction && props.lendingPool) {
            var multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.collateral.id));
            var normalisedValue = value / multiplier;

            props.withdrawFunction(props.lendingPool?.collateral.id, normalisedValue);
            await fetchAssetWalletBalance();
            await fetchAssetStakedBalance();
        }
    }

    async function deposit(value: number) {
        if (props.depositFunction && props.lendingPool) {
            props.depositFunction(props.lendingPool?.collateral.id, value);
            await fetchAssetWalletBalance();
            await fetchAssetStakedBalance();
        }
    }

    async function approve() {
        // console.log(approvalAmounts);
        if (props.lendingPool == null) return;

        var address = props.lendingPool.pair.uniswapV2PairAddress;
        if (props.approveFunction) props.approveFunction(address);
        if (props.getAllowanceFunction) {
            var a = await props.getAllowanceFunction(address);
            setAllowance(a);
        }
    }

    async function withdrawApprove() {
        // console.log(approvalAmounts);
        if (props.lendingPool == null) return;

        var address = props.lendingPool?.collateral.id;
        if (props.approveFunction) props.approveFunction(address);
        if (props.getAllowanceFunction) {
            var a = await props.getAllowanceFunction(address);
            setWithdrawAllowance(a);
        }
    }

    //pool stats
    // var stakeApr = Number(props.lendingPool?.collateral.kinkBorrowRate) * 365.25 * 24 * 60 * 60 || 0;
    // var stakeTvl = Number(props.borrowable?.totalSupplyUSD) || 0;
    // console.log(props.bombFactories);
    var farmApr = props.farmApyStats ? Number(props.farmApyStats['bomb-' + props.lendingPool?.borrowable0.underlying.symbol.toLowerCase() + '-' + props.lendingPool?.borrowable1.underlying.symbol.toLowerCase()].totalApy) * 100 : 0;
    var farmBalanceUsd = Number(props.lendingPool?.collateral.totalBalanceUSD) || 0;
    var farmBalance = Number(props.lendingPool?.collateral.totalBalance) || 0;
    var utilization =
        props.bombFactories && props.bombFactories.length > 0
            ? Number(props.bombFactories[0].totalBorrowsUSD / props.bombFactories[0].totalSupplyUSD) * 100
            : 0;
    var collateralExchangeRate = Number(props.lendingPool?.collateral.totalBalanceUSD) / Number(props.lendingPool?.collateral.totalBalance);

    function openStakeModal() {
        setStakeModalIsOpen(true);
    }

    function closeStakeModal() {
        setStakeModalIsOpen(false);
    }

    function openUnstakeModal() {
        setUnstakeModalIsOpen(true);
    }

    function closeUnstakeModal() {
        setUnstakeModalIsOpen(false);
    }

    function openZapModal() {
        setZapModalIsOpen(true);
    }

    function closeZapModal() {
        setZapModalIsOpen(false);
    }

    return (
        <div
            className={
                "px-6 pb-6 pt-3 overflow-hidden bg-white card relative shadow rounded-xl text-left flex flex-col text-gray-800 min-w-full md:min-w-fit w-[22rem] min-w-full md:min-w-[22rem] dark:text-light" +
                (props.featured
                    ? " p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 "
                    : " bg-white dark:bg-dark2-500") +
                (props.className ? ` ${props.className}` : "")
            }
        >
            <div className=" flex flex-col items-center">
                <div className="flex font-urbanist mb-2">
                    <div className="text-3xl font-extrabold">
                        {props.lendingPool?.borrowable0.underlying.symbol}-
                        {props.lendingPool?.borrowable1.underlying.symbol}
                    </div>
                </div>
                <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                    <MiniPrice
                        icon={iconPieChart}
                        iconAlt="pie chart"
                        title="APY"
                        value={roundAndFormatNumber(farmApr, 2) + "%"}
                    />
                    <MiniPrice
                        icon={iconLock}
                        iconAlt="lock"
                        title="TVL"
                        value={"$" + roundAndFormatNumber(farmBalanceUsd, 2, true)}
                        right
                    />
                </div>
                <div className="mb-4 relative">
                    <RadialGraph imageUrl={props.imageUrl} value={utilization} hideRadial />
                </div>

                <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                    <MiniPrice
                        icon={iconStaked}
                        iconAlt="lock"
                        title={`STAKED`}
                        value={'$' + roundAndFormatNumber((stakedBalance / stakedBalanceMultiplier) * collateralExchangeRate, 2) + ' (' + roundAndFormatNumber(stakedBalance, 6) + " LP)"}
                        right
                    />
                </div>
                {stakedDebt > 0 && (
                    <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                        <MiniPrice
                            icon={iconStaked}
                            iconAlt="lock"
                            title={`DEBT`}
                            textColor={'lightcoral'}
                            value={'-' + roundAndFormatNumber(stakedDebt, 3) + " LP"}
                        />
                        <MiniPrice
                            icon={iconStaked}
                            iconAlt="lock"
                            title={`LP Equity`}
                            tooltip={`LP Equity = Total LP - Debt LP`}
                            value={roundAndFormatNumber(stakedBalance - stakedDebt, 3) + " LP"}
                            right
                        />
                    </div>
                )}

                <div className="flex gap-3 w-full flex-wrap">
                    {/* <button className="whitespace-nowrap flex justify-evenly items-center flex-1 rounded-full outline-[#b6b7c2] bg-gradient-to-b from-[#fee22e] to-[#fed647] text-white uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none ring ring-[#b6b7c2] ring-opacity-75 focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75">
                        <img className="h-6 pr-2" src={iconZap} alt="lightning" />
                        Zap In
                    </button> */}
                    {!web3Connected ?
                        (
                            <ConnectButton
                                className=""
                                connectWeb3={connectWeb3}
                                disconnectWeb3={disconnectWeb3}
                                web3Connected={web3Connected}
                                walletAddress={walletAddress}
                            />
                        )
                        : (
                            <>
                                {props.depositFunction != null ?
                                    allowance == 0 ? (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => approve()}
                                            >
                                                Stake Approve
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="whitespace-nowrap flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap text-black"
                                                onClick={() => openStakeModal()}
                                            >
                                                Stake
                                            </button>
                                            {stakeModalIsOpen && (<StakeModal
                                                isOpen={stakeModalIsOpen}
                                                title="Stake"
                                                onRequestClose={closeStakeModal}
                                                depositFunction={(value: number) => {
                                                    closeStakeModal();
                                                    return deposit(value);
                                                }}
                                                walletBalance={walletBalance}
                                                amountElementId={"stake_farm_" + props.lendingPool?.collateral?.id}
                                                symbol={props.lendingPool?.borrowable0.underlying.symbol + '-' + props.lendingPool?.borrowable1.underlying.symbol}
                                            />)}
                                        </>
                                    ) : null}

                                {props.withdrawFunction != null ?
                                    withdrawAllowance == 0 && stakedBalance > 0 ? (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#b6b7c2] to-[#8c95a7] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#8c95a7] focus:outline-none focus:ring focus:ring-[#8c95a7] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => withdrawApprove()}
                                            >
                                                Un-Stake Approve
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#b6b7c2] to-[#8c95a7] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#8c95a7] focus:outline-none focus:ring focus:ring-[#8c95a7] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => openUnstakeModal()}
                                            >
                                                Un-Stake
                                            </button>
                                            {unstakeModalIsOpen && (<StakeModal
                                                isOpen={unstakeModalIsOpen}
                                                title="Un-Stake"
                                                onRequestClose={closeUnstakeModal}
                                                depositFunction={(value: number) => {
                                                    closeUnstakeModal();
                                                    return withdraw(value);
                                                }}
                                                stakedBalance={stakedBalance}
                                                stakedBalanceMultiplier={stakedBalanceMultiplier}
                                                stakedDebt={stakedDebt}
                                                debt0={debt0}
                                                debt1={debt1}
                                                lpStat={props.lpStat}
                                                lendingPool={props.lendingPool}
                                                amountElementId={"unstake_farm_" + props.lendingPool?.collateral?.id}
                                                symbol={props.lendingPool?.borrowable0.underlying.symbol + '-' + props.lendingPool?.borrowable1.underlying.symbol}
                                            />)}
                                        </>
                                    ) : null}
                            </>
                        )}
                </div>
            </div>
            {web3Connected && (
                <div>
                    <p className="border-t mt-4 mb-4 w-full" style={{ "lineHeight": 0 }}>&nbsp;</p>
                    <div className="text-center">
                        <button onClick={() => openZapModal()}
                                className="relative inline-flex items-center pl-6 pr-4 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue">
                        <span
                            className="text-black dark:text-white uppercase pr-2">Zap to {props.lendingPool?.borrowable0.underlying.symbol + '-' + props.lendingPool?.borrowable1.underlying.symbol + ' LP'}</span>
                        </button>
                    </div>
                    {zapModalIsOpen && (<ZapModal
                        isOpen={zapModalIsOpen}
                        title={"Zap " + props.lendingPool?.borrowable0.underlying.symbol + '-' + props.lendingPool?.borrowable1.underlying.symbol + ' LP'}
                        onRequestClose={closeZapModal}
                        zapFunction={(value: number) => {
                            closeZapModal();
                        }}
                        amountElementId={"zap_" + props.lendingPool?.borrowable0.underlying.symbol + '_' + props.lendingPool?.borrowable1.underlying.symbol}
                        symbol={props.lendingPool?.borrowable0.underlying.symbol + '-' + props.lendingPool?.borrowable1.underlying.symbol + ' LP'}
                        symbolAddress={props.lendingPool?.pair.uniswapV2PairAddress ? props.lendingPool?.pair.uniswapV2PairAddress : ''}
                        symbolIsLp={true}
                    />)}
                </div>
            )}
        </div>
    );
};

export default FarmCard;
