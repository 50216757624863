import axios from "axios";
import { LendingGraphResponse } from "../../../Common/Models/LendingGraph";
import { UserPositionsResponse } from "../../../Common/Models/UserPositions";
import { BeefyFarmApyStatsResponse, } from "../../../Common/Models/BeefyFarmApyStats";
import { BeefyFarmTvlStatsResponse } from "../../../Common/Models/BeefyFarmTvlStats";

export const getLendingPool = async () => {
    const resp = await axios.post("https://api.thegraph.com/subgraphs/name/bombmoney/bombmax", {
        query: '{  bombFactories(first: 1) {    totalSupplyUSD    totalBalanceUSD    totalBorrowsUSD  }  lendingPools(where: { id_in: ["0x9da55ec7aeef5ea7e78c9da849d47540fe3cf4ba", "0xe1e3b06037e4d7f2ade340002728280647572d26"] } ) {    borrowable0 {      accrualTimestamp      borrowIndex      borrowRate      exchangeRate      id      kinkBorrowRate      kinkUtilizationRate      reserveFactor      totalBalance      totalBalanceUSD      totalBorrows      totalBorrowsUSD      totalSupplyUSD      underlying {        decimals        derivedUSD        id        name        symbol      }    }    borrowable1 {      accrualTimestamp      borrowIndex      borrowRate      exchangeRate      id      kinkBorrowRate      kinkUtilizationRate      reserveFactor      totalBalance      totalBalanceUSD      totalBorrows      totalBorrowsUSD      totalSupplyUSD      underlying {        decimals        derivedUSD        id        name        symbol      }    }    collateral {      exchangeRate      id      liquidationIncentive      safetyMargin      totalBalance      totalBalanceUSD    }    id    pair {      derivedETH      derivedUSD      isVaultToken      reserve0      reserve1      rewardsToken {        decimals        derivedUSD        id        name        symbol      }      token0 {        derivedETH        derivedUSD        id      }      token0Price      token1 {        derivedETH        derivedUSD        id      }      token1Price      totalSupply      uniswapV2PairAddress    }  }}',
        variables: null,
    });
    return resp.data as LendingGraphResponse;
};

export const getUserPositions = async (id: string) => {
    const resp = await axios.post("https://api.thegraph.com/subgraphs/name/bombmoney/bombmax", {
        query: `{ user(id: "${id}"){ id collateralPositions{ balance collateral{ totalBalance underlying{ token0{ symbol derivedUSD } token1{ symbol derivedUSD } token0Price token1Price } totalBalanceUSD } } supplyPositions{ balance borrowable{ underlying{ symbol derivedUSD } } } borrowPositions{ borrowBalance borrowable{ underlying{ symbol derivedUSD } } } }}`,
        variables: null,
    });

    return resp.data as UserPositionsResponse;
};

export const getFarmApyStats = async () => {
    const resp = await axios.get("https://api.bomb.farm/apy/breakdown", {});
    return resp as BeefyFarmApyStatsResponse;
};

export const getFarmTvlStats = async () => {
    const resp = await axios.get("https://api.bomb.farm/tvl", {});
    return {'data': resp.data['56']} as BeefyFarmTvlStatsResponse;
};

// export const executeLendingDeposit = async (routerAddress: string, assetAddress: string, amount: number) => {

// };