import React, { useContext, useEffect } from "react";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import { LendingPool } from "../Common/Models/LendingGraph";
import FarmCard from "../Cards/FarmCard";
import RiskGraph, { RiskType } from "../Components/CardControls/RiskGraph";
import imgBombBtc from "../Images/bomb-btc.png";
import SocialBar from "../Components/Social/SocialBar";

function FarmPage() {
    const { lendingGraph, farmApyStats, lpStats, depositCollateral, fetchAssetApprovalAmount, approveAsset, withdrawCollateral, fetchAssetBalance, fetchAssetDebt, fetchAssetExchangeRate } = useContext(Web3Context);

    const bombFactories = lendingGraph?.data.bombFactories;

    return (
        <>
            <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
                <h1 className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">
                    Farm your liquidity pairs and unlock more rewards{" "}
                    <span className="italic font-extrabold whitespace-nowrap">
                        <span className="text-primary-500">//</span> BOMB Liquidity Farming{" "}
                        <span className="text-primary-500">//</span>
                    </span>
                    <span className="pl-3 font-extrabold text-xl inline-block">
                        Medium Risk <RiskGraph riskType={RiskType.MEDIUM} />
                    </span>
                </h1>
                <div className="mb-6 font-bold ">Earn even greater rewards by farming the liquidity pairs</div>

                <div className="max-w-lg lg:max-w-none rounded-lg flex-1 mt-2 mx-auto md:mx-0 contents md:block">
                    <div className="gap-8 w-full flex flex-wrap justify-center md:justify-start">
                        {lendingGraph?.data.lendingPools.map((x) => {
                            return (
                                <FarmCard
                                    key={x.id}
                                    lendingPool={x}
                                    farmApyStats={farmApyStats ? farmApyStats.data : undefined}
                                    // farmTvlStats={farmTvlStats ? farmTvlStats.data : undefined}
                                    depositFunction={depositCollateral}
                                    withdrawFunction={withdrawCollateral}
                                    approveFunction={approveAsset}
                                    getAllowanceFunction={fetchAssetApprovalAmount}
                                    lpStat={lpStats ? lpStats[x.borrowable0.underlying.symbol + '-' + x.borrowable1.underlying.symbol + '-LP'] : undefined}
                                    imageUrl={imgBombBtc}
                                    bombFactories={bombFactories}
                                    fetchAssetBalanceFunction={fetchAssetBalance}
                                    fetchAssetDebtFunction={fetchAssetDebt}
                                    fetchAssetExchangeRateFunction={fetchAssetExchangeRate}
                                />
                            );
                        })}
                    </div>
                </div>
                <SocialBar className="mt-12" />
            </main>
        </>
    );
}

export default FarmPage;
