import React, { useContext } from "react";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import RiskGraph, { RiskType } from "../Components/CardControls/RiskGraph";
import SocialBar from "../Components/Social/SocialBar";
import BorrowCard from "../Cards/BorrowCard";
import imgBombBtc from "../Images/bomb-btc.png";

function BorrowPage() {
    const {
        fetchAssetBalance,
        fetchAssetDebt,
        fetchAssetExchangeRate,
        fetchAssetApprovalAmount,
        leveragePool,
        deleveragePool,
        borrowApproveAsset,
        fetchAssetBorrowApprovalAmount,
        approveAsset,
        lendingGraph,
        farmApyStats,
        lpStats
    } = useContext(Web3Context);

    return (
        <>
            <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
                <h1 className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">
                    Experience earning high returns without the stress{" "}
                    <span className="italic font-extrabold whitespace-nowrap">
                        <span className="text-primary-500">//</span> BOMB Lending &amp; Borrowing{" "}
                        <span className="text-primary-500">//</span>
                    </span>
                    <span className="pl-3 font-extrabold text-xl inline-block">
                        High Risk <RiskGraph riskType={RiskType.HIGH} />
                    </span>
                </h1>
                <div className="mb-6 font-bold ">Earn even greater rewards by farming the liquidity pairs</div>

                <div className="max-w-lg lg:max-w-none rounded-lg flex-1 mt-2 mx-auto md:mx-0 contents md:block">
                    <div className="gap-8 w-full flex flex-wrap justify-center md:justify-start">
                        {lendingGraph?.data.lendingPools.map((x) => {
                            return (
                                <BorrowCard
                                    key={x.id}
                                    imageUrl={imgBombBtc}
                                    lendingPool={x}
                                    getAllowanceFunction={fetchAssetApprovalAmount}
                                    getBorrowAllowanceFunction={fetchAssetBorrowApprovalAmount}
                                    leverageFunction={leveragePool}
                                    deleverageFunction={deleveragePool}
                                    approveFunction={approveAsset}
                                    borrowApproveFunction={borrowApproveAsset}
                                    farmApyStats={farmApyStats ? farmApyStats.data : undefined}
                                    fetchAssetBalanceFunction={fetchAssetBalance}
                                    fetchAssetDebtFunction={fetchAssetDebt}
                                    fetchAssetExchangeRateFunction={fetchAssetExchangeRate}
                                    lpStat={lpStats ? lpStats[x.borrowable0.underlying.symbol + '-' + x.borrowable1.underlying.symbol + '-LP'] : undefined}
                                />
                            );
                        })}
                    </div>
                </div>
                <SocialBar className="mt-12" />
            </main>
        </>
    );
}

export default BorrowPage;
