import React, { FC, useContext, useEffect, useState } from "react";
import { roundAndFormatNumber } from "../Common/HelperFunctions";
import { Borrowable } from "../Common/Models/LendingGraph";
import { SupplyPosition } from "../Common/Models/UserPositions";
import MiniPrice from "../Components/CardControls/MiniPrice";
import RadialGraph from "../Components/CardControls/RadialGraph";
import iconLock from "../Images/lock.png";
import iconPieChart from "../Images/pie-chart.png";
import iconStaked from "../Images/staked-icon.png";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import ConnectButton from "../Components/ConnectButton/ConnectButton";
import StakeModal from "../Modals/StakeModal";
import {
    getLendingPoolTokenSupplyAPY,
    getLendingPoolTokenTotalSupplyInUSD,
    getLendingPoolTokenUtilizationRate
} from "../Common/LendingPoolFunctions";
import ZapModal from "../Modals/ZapModal";

interface LendingCardProps {
    className?: string;
    featured?: boolean;
    borrowable: Borrowable;
    imageUrl: string;
    positions: SupplyPosition[];

    depositFunction?: (poolAsset: string, amount: number) => void;
    withdrawFunction?: (poolAsset: string, amount: number) => void;
    approveFunction?: (poolAsset: string) => void;
    getAllowanceFunction?: (poolAsset: string) => Promise<number>;
    fetchAssetBalanceFunction?: (poolAsset: string) => Promise<number>;
}

const LendingCard: FC<LendingCardProps> = (props) => {
    const [stakedBalance, setStakedBalance] = useState<number>(0);
    const [walletBalance, setWalletBalance] = useState<number>(0);
    const [allowance, setAllowance] = useState<number>(0);
    const [withdrawAllowance, setWithdrawAllowance] = useState<number>(0);
    const { connectWeb3, disconnectWeb3, web3Connected, walletAddress } = useContext(Web3Context);
    const [stakeModalIsOpen, setStakeModalIsOpen] = React.useState(false);
    const [unstakeModalIsOpen, setUnstakeModalIsOpen] = React.useState(false);
    const [zapModalIsOpen, setZapModalIsOpen] = React.useState(false);

    useEffect(() => {
        if (web3Connected) {
            fetchApproval();
            fetchWithdrawApproval();
            fetchAssetWalletBalance();
            fetchAssetStakedBalance();
        }
    }, [props.borrowable, web3Connected, walletAddress]);

    async function fetchApproval() {
        if (props.getAllowanceFunction && props.borrowable?.underlying.id) {
            var allow = await props.getAllowanceFunction(props.borrowable.underlying.id);
            setAllowance(allow);
        }
    }

    async function fetchWithdrawApproval() {
        // debugger;
        if (props.getAllowanceFunction && props.borrowable?.id) {
            var withdrawAllow = await props.getAllowanceFunction(props.borrowable?.id);
            setWithdrawAllowance(withdrawAllow);
        }
    }

    async function fetchAssetWalletBalance() {
        if (props.fetchAssetBalanceFunction && props.borrowable?.underlying.id) {
            var balance = await props.fetchAssetBalanceFunction(props.borrowable.underlying.id);
            setWalletBalance(balance);
        }
    }

    async function fetchAssetStakedBalance() {
        if (props.fetchAssetBalanceFunction && props.borrowable?.id) {
            var balance = await props.fetchAssetBalanceFunction(props.borrowable.id);
            var multiplier = Number(props.borrowable.exchangeRate);
            var trueBalance = balance * multiplier;
            setStakedBalance(trueBalance);
        }
    }

    async function withdraw(value: number) {
        if (props.withdrawFunction && props.borrowable) {
            var normalisedValue = value / Number(props.borrowable.exchangeRate);

            await props.withdrawFunction(props.borrowable?.id, normalisedValue);
            await fetchAssetWalletBalance();
            await fetchAssetStakedBalance();
        }
    }

    async function deposit(value: number) {
        if (props.depositFunction && props.borrowable) {
            await props.depositFunction(props.borrowable?.id, value);
            await fetchAssetWalletBalance();
            await fetchAssetStakedBalance();
        }
    }

    async function approve() {
        if (props.approveFunction && props.borrowable) {
            await props.approveFunction(props.borrowable?.underlying.id);
            await fetchApproval();
        }
    }

    async function withdrawApprove() {
        if (props.approveFunction && props.borrowable) {
            await props.approveFunction(props.borrowable?.id);
            await fetchWithdrawApproval();
        }
    }

    //pool stats
    var stakeApr = getLendingPoolTokenSupplyAPY(props.borrowable) * 100;
    var stakeTvl = getLendingPoolTokenTotalSupplyInUSD(props.borrowable);

    var stakeBalanceUsd = (stakedBalance * Number(props.borrowable?.underlying.derivedUSD)) || 0;
    var utilization = getLendingPoolTokenUtilizationRate(props.borrowable) * 100;
    console.log(`Staked: ${props.borrowable.underlying.symbol}   -   ${stakedBalance}   ($${stakeBalanceUsd}) `);

    function openStakeModal() {
        setStakeModalIsOpen(true);
    }

    function closeStakeModal() {
        setStakeModalIsOpen(false);
    }

    function openUnstakeModal() {
        setUnstakeModalIsOpen(true);
    }

    function closeUnstakeModal() {
        setUnstakeModalIsOpen(false);
    }

    function openZapModal() {
        setZapModalIsOpen(true);
    }

    function closeZapModal() {
        setZapModalIsOpen(false);
    }

    return (
        <div
            className={
                "px-6 pb-6 pt-3 overflow-hidden bg-white card relative shadow rounded-xl text-left flex flex-col text-gray-800 min-w-full md:min-w-fit w-[22rem]" +
                (props.featured
                    ? " p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 "
                    : " bg-white dark:bg-dark2-500") +
                (props.className ? ` ${props.className}` : "")
            }
        >
            <div className="flex flex-col items-center">
                <div className="flex font-urbanist mb-2 dark:text-light">
                    <div className="text-3xl font-extrabold">{props.borrowable?.underlying.symbol}</div>
                </div>
                <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                    <MiniPrice
                        icon={iconPieChart}
                        iconAlt="pie chart"
                        title="APR"
                        value={roundAndFormatNumber(stakeApr, 2) + "%"}
                    />
                    <MiniPrice
                        icon={iconLock}
                        iconAlt="lock"
                        title="TVL"
                        value={"$" + roundAndFormatNumber(stakeTvl, 2, true)}
                        right
                    />
                </div>
                <div className="mb-4 relative">
                    <RadialGraph imageUrl={props.imageUrl} value={utilization} />
                    {/* <div className="absolute right-0 bottom-0 translate-x-full">
                        <div className="border-t-[1px] border-black absolute -left-1 right-11 bottom-2 -translate-x-full"></div>
                        <div className="text-sm text-primary-500 font-extrabold">40%</div>
                        <div className="text-xs uppercase">Utilization</div>
                    </div> */}
                </div>

                <div className="flex flex-col text-center mb-4">
                    <div className="text-sm text-primary-500 font-extrabold">{roundAndFormatNumber(utilization)}%</div>
                    <div className="text-xs uppercase dark:text-light">Utilization</div>
                </div>
                <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                    <MiniPrice
                        icon={iconStaked}
                        iconAlt="lock"
                        title={`STAKED`}
                        value={roundAndFormatNumber(stakedBalance, 8)}
                        right
                    />
                </div>

                <div className="flex gap-3 w-full">
                    {!web3Connected ?
                        (
                            <ConnectButton
                                className=""
                                connectWeb3={connectWeb3}
                                disconnectWeb3={disconnectWeb3}
                                web3Connected={web3Connected}
                                walletAddress={walletAddress}
                            />
                        )
                        : (
                            <>
                                {props.depositFunction != null ?
                                    allowance == 0 ? (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => approve()}
                                            >
                                                Approve
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => openStakeModal()}
                                            >
                                                Stake
                                            </button>
                                            {stakeModalIsOpen && (<StakeModal
                                                isOpen={stakeModalIsOpen}
                                                title="Stake"
                                                onRequestClose={closeStakeModal}
                                                depositFunction={(value: number) => {
                                                    closeStakeModal();
                                                    return deposit(value);
                                                }}
                                                walletBalance={walletBalance}
                                                amountElementId={"stake_" + props.borrowable?.id}
                                                symbol={props.borrowable?.underlying.symbol}
                                            />)}
                                        </>
                                    ) : null}

                                {props.withdrawFunction != null ?
                                    withdrawAllowance == 0 && stakedBalance > 0 ? (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#b6b7c2] to-[#8c95a7] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#8c95a7] focus:outline-none focus:ring focus:ring-[#8c95a7] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => withdrawApprove()}
                                            >
                                                Un-Stake Approve
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="flex-1 rounded-full bg-gradient-to-b from-[#b6b7c2] to-[#8c95a7] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#8c95a7] focus:outline-none focus:ring focus:ring-[#8c95a7] focus:ring-opacity-75 whitespace-nowrap"
                                                onClick={() => openUnstakeModal()}
                                            >
                                                Un-Stake
                                            </button>
                                            {unstakeModalIsOpen && (<StakeModal
                                                isOpen={unstakeModalIsOpen}
                                                title="Un-Stake"
                                                onRequestClose={closeUnstakeModal}
                                                depositFunction={(value: number) => {
                                                    closeUnstakeModal();
                                                    return withdraw(value);
                                                }}
                                                stakedBalance={stakedBalance}
                                                amountElementId={"unstake_" + props.borrowable?.id}
                                                symbol={props.borrowable?.underlying.symbol}
                                            />)}
                                        </>
                                    ) : null}
                            </>
                        )}
                </div>
            </div>
            {web3Connected && (
                <div>
                    <p className="border-t mt-4 mb-4 w-full" style={{ "lineHeight": 0 }}>&nbsp;</p>
                    <div className="text-center">
                        <button onClick={() => openZapModal()}
                                className="relative inline-flex items-center pl-6 pr-4 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue">
                        <span
                            className="text-black dark:text-white uppercase pr-2">Zap to {props.borrowable?.underlying.symbol}</span>
                        </button>
                    </div>
                    {zapModalIsOpen && (<ZapModal
                        isOpen={zapModalIsOpen}
                        title={"Zap to " + props.borrowable?.underlying.symbol}
                        onRequestClose={closeZapModal}
                        zapFunction={(value: number) => {
                            closeZapModal();
                        }}
                        amountElementId={"zap_" + props.borrowable?.id}
                        symbol={props.borrowable?.underlying.symbol}
                        symbolAddress={props.borrowable?.underlying.id}
                        symbolIsLp={false}
                    />)}
                </div>
            )}
        </div>
    );
};

export default LendingCard;
